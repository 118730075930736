<template>
  <section class="agent-buttons">
    <a class="primary-button primary-button__classic primary-button__classic__agent" :href="agentClassic" target="_blank">agent classic</a>
    <a class="primary-button primary-button__new primary-button__new__agent" :href="agentNew" target="_blank">agent new</a>
  </section>
  <img  class="brand" src="../assets/images/dfw-logo2.svg" alt="wagerInternational">
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "https://agents.dfwcollege.online/";
      const agentNew = "https://adm.dfwcollege.online/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
